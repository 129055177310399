<template>
  <section class="main-login">
    <div class="main-box flexed">
      <div class="login-logo-box">
        <img src="../../assets/images/logo_1080.png" alt="抖工科技">
      </div>
      <Card class="login-card" icon="log-in" :bordered="false">
        <span slot="title">
            注册企业
        </span>
        <div class="form-con">
          <Form ref="enterpriseForm" :model="enterpriseForm" :rules="enterpriseRules">
            <FormItem prop="phone">
              <Input size="large" class="login_input" v-model="enterpriseForm.phone" @on-change="phoneOnChange" placeholder="请输入手机号">
              </Input>
            </FormItem>
            <FormItem prop="code">
              <Row class="flexed">
                <Col span="13">
                  <Input size="large" class="login_input" v-model="enterpriseForm.code" placeholder="请输入短信验证码">
                  </Input>
                </Col>
                <Col span="1"> </Col>
                <Col span="10">
                  <Button
                    v-if="sendAuthCode"
                    :disabled="disabledCode"
                    size="large"
                    class="button-login-code"
                    type="primary"
                    @click="getCode"
                    long>
                    获取验证码
                  </Button>
                  <Button
                    v-else
                    size="large"
                    class="button-login-code"
                    :class="{'disabled-button': disabledCode}"
                    type="primary"
                    long>
                    {{ codeTime }}s
                  </Button>
                  <!-- <Button v-show="!sendAuthCode" size="large" class="login-code" type="primary" long>{{ codeTime }}</Button> -->
                </Col>
              </Row>
            </FormItem>
            <FormItem>
              <Button size="large"
                class="button-login"
                @click="enterpriseSubmit('enterpriseForm')"
                type="primary"
                long>
                下一步
              </Button>
            </FormItem>
          </Form>
          <div class="bottom-btn-list">
            <span @click="goLogin" class="register-jump">登录企业</span>
          </div>
        </div>
      </Card>
    </div>
  </section>
</template>

<script>
import md5 from "js-md5"
  export default {
    data() {
      return {
        disabledCode: true,
        // 获取验证码按钮状态
        sendAuthCode: true,
        smsCodeTimer: null,
        // 验证码计时器初始值
        codeTime: 0,
        // 表单对象
        enterpriseForm: {},
        // 表单验证
        enterpriseRules: {
          phone: [{
            type: 'number', required: true, message: '请填写正确的手机号', transform(value) {
              var msg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
              if (!msg.test(value)) {
                return false;
              } else {
                return Number(value);
              }
            }
          }],
          code: [
            {required: true, message: '验证码不能为空'},
            {type:"string",min:4,message:"验证码输入错误"},
            {type:"string",max:4,message:"验证码输入错误"},
            ],
        }
      }
    },
    methods: {
      // 验证手机号
      phoneOnChange(e) {
        var msg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
        if (!msg.test(e.target.value)) {
          this.disabledCode = true;
          return false;
        } else {
          this.disabledCode = false;
          return Number(e.target.value);
        }
      },
      // 获取验证码
      getCode() {
        let obj = {...this.enterpriseForm, type: "2",reconstructVersion:true};
        let sendCode = this.common.getStorage("phone");
        if (sendCode) {
          this.$Message.error("已发送验证码");
          return;
        }
        this.common.setStorage("phone", this.enterpriseForm.phone, 1);
        this.$axios.post(`${this.apiUrlCros}/sapi/v3/sms`, obj, {
          "headers": {
            "verifyPhone": this.enterpriseForm.phone
          }
        })
        .then(res => {
          if (res.data.code == "200" || res.data.code == "0") {
            this.$Message.success("发送成功")
            this.sendAuthCode = false;
            this.codeTime = 60;
            this.smsCodeTimer = setInterval(() => {
              this.codeTime--;
              if (this.codeTime <= 0) {
                this.sendAuthCode = true;
                clearInterval(this.smsCodeTimer);
              }
            }, 1000);
          } else {
            this.$Message.error(res.data.msg);
            this.common.delStorage("phone");
          }
        })
          .catch(err => {
            // this.$Message.error(err);
            this.common.delStorage("phone");
          })
      },
      // 下一步跳转
      enterpriseSubmit(name) {
        this.$refs[name].validate(valid => {
          if (valid) {
            this.$axios.post(`${this.apiUrlCros}/sapi/v3/checkSms`, {
                phone: this.enterpriseForm.phone,
                code: this.enterpriseForm.code
              }
            ).then(res => {
              
              if (res.data.code == "200") {
                this.sendAuthCode = true; // 注册失败后显示获取code按钮
                clearInterval(this.smsCodeTimer); // 清除计时器
                this.$router.push({name: 'register', params: {phone: this.enterpriseForm.phone, code: this.enterpriseForm.code}})
              } else {
                this.$Message.error(res.data.msg);
                this.sendAuthCode = true; // 注册失败后显示获取code按钮
                clearInterval(this.smsCodeTimer); // 清除计时器
              }
            }).catch(err => {
              this.$Message.error(err);
            });
          }
        })
      },
      // 跳转登录
      goLogin() {
        this.$router.push({name: 'login'})
      },
      returnHome() {
        this.$router.push({name:"home"})
      },
      toProtocolPage (type) {
        this.common.toProtocolPage(type);
      },
    }
  }
</script>

<style lang="stylus">
.main-login
  .disabled-button
    border-color #cccccc
    color #cccccc
  
  //  @import '../../assets/styles/login'
  /* @media only screen and (min-width: 1280px) {
    .register-con {
      right: 21%;
      width: 26.03%;
      height: 45%;
    }

    .login-code {
      margin-top: 0px;
      width: 120px;
      margin-left: 12px;
    }

    .register-con .enterpriseSpan span {
      font-size: 15px;
      margin-top: 10px;
    }

    .ivu-form-item {
      margin-bottom: 12px;
    }

    .ivu-input {
      margin-bottom: 0px;
    }

    .ivu-form-item-error-tip {
      margin-top: 12px;
    }

    .login_buttonBg {
      margin-top: 20px;
    }

    .enterpriseSpan {
      margin-top: -9px;
      font-size: 15px;
    }
  }

  @media only screen and (min-width: 1440px) {
    .register-con {
      height: 40%;
      right: 18%;
      width: 28.03%;
    }

    .login-code {
      margin-top: 0px;
      width: 150px;
      margin-left: 12px;
    }

    .register-con .enterpriseSpan span {
      font-size: 15px;
      margin-top: 10px;
    }

    .ivu-form-item {
      margin-bottom: 12px;
    }

    .ivu-input {
      margin-bottom: 0px;
    }

    .ivu-form-item-error-tip {
      margin-top: 12px;
    }

    .login_buttonBg {
      margin-top: 10px;
    }

    .register-con .ivu-btn {
      margin-top: 0px !important;
    }

    .enterpriseSpan {
      margin-top: -9px;
      font-size: 15px;
    }

  }

  @media only screen and (min-width: 1600px) {
    .register-con {
      right: 18%;
      width: 28.03%;
      height: 47%;
    }

    .login-code {
      margin-top: 0px;
      width: 178px;
      margin-left: 23px;
    }

    .register-con .enterpriseSpan span {
      font-size: 24px;
      margin-top: 20px;
    }

    .ivu-form-item {
      margin-bottom: 25px;
    }

    .ivu-input {
      margin-bottom: 0px;
    }

    .ivu-form-item-error-tip {
      margin-top: 12px;
    }

    .login_buttonBg {
      margin-top: 10px;
    }

    .register-con .ivu-btn {
      margin-top: 0px !important;
    }

    .enterpriseSpan {
      margin-top: -15px;
    }
  }

  @media only screen and (min-width: 1920px) {
    .register-con {
      right: 24%;
      width: 490px;
      height: 460px !important;
    }

    .login-code {
      margin-top: 0px;
      width: 178px;
      margin-left: 23px;
    }

    .register-con .enterpriseSpan span {
      font-size: 24px;
      margin-top: 20px;
    }

    .ivu-form-item {
      margin-bottom: 25px;
    }

    .ivu-input {
      margin-bottom: 0px;
    }

    .ivu-form-item-error-tip {
      margin-top: 12px;
    }

    .login_buttonBg {
      margin-top: 10px;
    }

    .register-con .ivu-btn {
      margin-top: 0px !important;
    }

    .enterpriseSpan {
      margin-top: -15px;
    }
  }

  @media only screen and (min-height: 1080px) {
    .register-con {
      height: 460px;
    }

  }

  @media only screen and (min-height: 1200px) {
    .register-con {
      height: 36%;
    }

  } */

</style>
